<template>
    <DetailContent
        :queryData="formLabelAlign"
        :showTab="showTab"
        v-if="formLabelAlign"
    ></DetailContent>
</template>

<script>
import DetailContent from "@/components/ship/DetailContent/index.vue";
export default {
    data() {
        return {
            formLabelAlign: null,
            showTab: "NB",
        };
    },
    components: {
        DetailContent,
    },
    computed: {},

    mounted() {
        this.showTab = localStorage.getItem("showTab") || this.showTab;
        window.sessionStorage.setItem("activeSearchPath", ""); //导航栏清空
        if (this.$route.query.data) {
            let formObj = decodeURIComponent(this.$route.query.data);
            this.formLabelAlign = JSON.parse(formObj);
            console.log(this.formLabelAlign);
        }
    },
};
</script>
